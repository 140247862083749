import foysal from "../../../Assets/Team/thinkystorm-amirfaysal-ceo.png";
import Asshik from "../../../Assets/Team/thinkystorm-Ashik.png";
import asif from "../../../Assets/Team/thinkystorm-asif.png";
import Naimur from "../../../Assets/Team/naimur.png";
import likhon from "../../../Assets/Team/likhon.png";
import mash from "../../../Assets/Team/Mashbah.png";
import Miskatur from "../../../Assets/Team/miskatur.png";

const teamData = [
  {
    _id: 1,
    name: "Amir Faysal",
    position: "CEO",
    image: `${foysal}`,
  },
  {
    _id: 2,
    name: "Ashikul Islam",
    position: "CTO ",
    image: `${Asshik}`,
  },
  {
    _id: 3,
    name: "Muhammad Asif",
    position: "Project Manager",
    image: `${asif}`,
  },
  {
    _id: 4,
    name: "Naimur Rahaman ",
    position: "CPO",
    image: `${Naimur}`,
  },
  {
    _id: 5,
    name: "Likhon Ahmed",
    position: "HR",
    image: `${likhon}`,
  },

  {
    _id: 6,
    name: "Masbah Uddin",
    position: "UI/UX Designer",
    image: `${mash}`,
  },
  {
    _id: 7,
    name: "Miskatur Rahman",
    position: "Advisor",
    image: `${Miskatur}`,
  },
];

export default teamData;
