import React from 'react';
import Technology from '../../Components/Technology/Technology';

const DevelopmentLifeCycle = () => {
    return (
        <div>
            <Technology></Technology>
        </div>
    );
};

export default DevelopmentLifeCycle;