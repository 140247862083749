import React from "react";

const HeroImg = () => {
  return (
    <div>
      <div>
        <img src={"img/Home/hero-img.png"} alt="hero section img" />
      </div>
    </div>
  );
};

export default HeroImg;
