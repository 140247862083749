import React from 'react';

const AdminIndex = () => {
    return (
        <div className=' text-black'>
          Welcome to admin dashboard
        </div>
    );
};

export default AdminIndex;